<script>
import Icons from "@/components/reuseables/Icons.vue";
import StatusBar from "@/components/reuseables/StatusBar.vue";

export default {
  name: 'ViewBooking',
  components: {Icons,StatusBar},
  data(){
    return{
      imageUrl: null,

      userDetails: {
      fullName : 'Zoe Davids',
      email: 'zoedavids@gmail.com',
      gender: 'Male',
      phone: '08100000000',
      bookingId: '04773874',
      departure: 'Lagos-Jibowu',
      arrival: 'Warri-Effuru',
      tripDate: '12-June-2023',
      amount: '\u20A615000',
      accountName: 'GUO',
      accountNo: '04773874',
      paymentId: 'P4773874',
      paymentTime: '5:00AM 12-June-2023',

      },
    }
  },
  methods:{
    handleImageUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleShowComponent(){
      this.showComponent = false
      this.$nextTick(() => {
        this.showComponent = true
      })
    },
  }
}

</script>

<template>
  <div class="tw-flex tw-justify-center">
    <div class="page-form">

      <div class="tw-flex mb-5 btn">
        <v-btn class="" @click="$router.push({name:'RibsDashboard'})"> <icons name="back" width="16px" class="mr-2"/>
          <p class="btn-txt tw-normal-case">View Booking</p></v-btn>
      </div>

      <div class="white-bg tw-flex ">

        <validation-provider name="PostImage" rules="" v-slot="{ classes, errors }">
          <div class="tw-flex tw-justify-center tw-flex-col image-body">
            <input type="file" class="image-input" id="file" @change="handleImageUpload"/>
            <label class="image-label ml-5 mt-5" for="file"  >
              <img v-if="imageUrl" :src="imageUrl" alt="" class="image"/>
              <img v-else class="image-icon" src="@/assets/image-input.svg">
            </label>
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <v-col sm="5" class="user-details">

          <div class="tw-flex">
            <v-col class="lighter-text" cols="6" sm="6">Full Name</v-col>
            <p class="darker-text" id="darker-txt">{{ userDetails.fullName}}</p>
          </div>



          <div class="tw-flex">
            <v-col class="lighter-text" cols="6" sm="6">Email</v-col>
            <span class="darker-text" id="darker-txt">{{ userDetails.email}}</span>
          </div>

          <div class="tw-flex">
            <v-col class="lighter-text" cols="6" sm="6">Gender</v-col>
            <span class="darker-text" id="darker-txt">{{ userDetails.gender}}</span>
          </div>

          <div class="tw-flex">
            <v-col class="lighter-text" cols="6" sm="6">Phone</v-col>
            <span class="darker-text" id="darker-txt">{{ userDetails.phone}}</span>
          </div>

          <div class="tw-flex">
            <v-col class="lighter-text" cols="6" sm="6">BookingID</v-col>
            <span class="darker-text" id="darker-txt">{{ userDetails.bookingId}}</span>
          </div>
        </v-col>
      </div>

      <div class="white-bg tw-flex justify-space-between mt-5 tw-h-90" id="white-bg">
        <div class="body">
        <div class="mb-5">
          <v-col class="lighter-text">Departure</v-col>
          <v-col class="darker-text">{{userDetails.departure}}</v-col>
        </div>

        <div class="mb-5">
          <v-col class="lighter-text">Arrival</v-col>
          <v-col class="darker-text">{{userDetails.arrival}}</v-col>
        </div>

        <div class="mb-5">
          <v-col class="lighter-text">Trip Date</v-col>
          <v-col class="darker-text">{{userDetails.tripDate}}</v-col>
        </div>
      </div>

        <div>
          <div class="mb-5" id="mobile-style">
            <v-col class="lighter-text">Amount</v-col>
            <v-col class="darker-text">{{userDetails.amount}}</v-col>
          </div>

          <div class="mb-5">
            <v-col class="lighter-text" id="mobile-style">Account Name</v-col>
            <v-col class="darker-text">{{userDetails.amount}}</v-col>
          </div>

          <div>
            <v-col class="lighter-text" id="mobile-style">Account No.</v-col>
            <v-col class="darker-text">{{userDetails.accountNo}}</v-col>
          </div>
        </div>

        <div class="payment">
          <div class="mb-5">
            <v-col class="lighter-text">Payment ID</v-col>
            <v-col class="darker-text">{{userDetails.paymentId}}</v-col>
          </div>

          <div class="mb-5" id="payment-time-mobile">
            <v-col class="lighter-text">Payment Time</v-col>
            <v-col class="darker-text">{{userDetails.paymentTime}}</v-col>
          </div>

          <div>
            <v-col class="lighter-text">Status</v-col>
            <span><status-bar text="Success" class="status"/></span>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>

<style scoped lang="scss">
.image-label{
  width: 164px;
  height: 170px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  border: 2px dashed rgba(0, 74, 173, 0.42);
  background: rgba(15, 99, 255, 0.06);
  margin-top: 10px;

  @media (max-width: 768px) {
    padding: 20px;
  }
  @media screen and (max-width: 375px){
    width: 267px;
  }
}

.image-label .image[src] {
  max-width: 100%;
  display: block;
  margin: auto;
  object-fit: cover;
  object-position: top;
}

.image-label .image {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  object-fit: cover;
}
.image-body{
  @media screen and (max-width: 375px){
    margin-right: -50px;
  }
}
.image-input{
  display: none;
}
.image-icon{
  cursor: pointer;
}

.white-bg{
  border-radius: 10px;
  height: fit-content;
  background: #FEFFFF;
  width: 464px;
  padding: 10px;
  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
    width: 315px;
  }
}
#white-bg {
  padding: 20px;
  @media screen and (max-width: 375px){
  }
}

.btn-txt{
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0;
}
.lighter-text{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #939393;
}

.darker-text{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #263238;
}
#darker-txt{
  margin-top: 12px;
}
.btn{
  margin-left: -20px;
  @media screen and (max-width: 375px){
    margin-left: -17px;
  }
}
#mobile-style{
  @media screen and (max-width: 375px){
    margin-right: 54px;
  }
}
.payment{
  @media screen and (max-width: 375px){
    display: flex;
    flex-wrap: wrap;
  }
}
.status{
  background: rgba(0, 196, 140, 0.66);
  margin-top: 5px;
}



</style>