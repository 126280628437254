<script>
export default {
  props: ['text'],
}
</script>

<template>
  <div :class="{ 'successButton': text === 'Success', 'redButton': text !== 'Success' }">{{text}}</div>
</template>

<style scoped lang="scss">
.successButton{
  background: #00C48C;
  color: #0F674E;
  border-radius: 10px;
  width: 79px;
  height: 24px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  padding-top: 3px;
}

.redButton{
  width: 79px;
  height: 24px;
  border-radius: 10px;
  background: #C84867;
  color: #71011C;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  padding-top: 3px;

}
</style>

